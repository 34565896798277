<template>
  <div class="container p-tb-2 details-container">
    <h2>Order #1{{ orderId.toString().padStart(5, '0') }}</h2>
    <div class="row" v-if="order">
      <div class="col-lg-7 col-xs-12">
        <div class="row">
          <template v-for="({ id, name, quantity }, i) in order.products">
            <div class="col-lg-12 col-xs-12 px-5 cart-row tile" :key="i">
              <div class="cart-item">
                <div class="tile-image mr-2 text-center" style="width: 70px">
                  <img :src="products ? products[id].image : '/img/logo-small.svg'" alt="" height="70" />
                </div>
                <router-link v-if="products" :to="`/product/${products[id].slug}`" class="text-color-purple underlined">
                  {{ products[id].title }}
                </router-link>
                <span v-else>{{ name }}</span>
                <div class="actions">
                  <span class="mr-2">Quantity: </span>
                  <span style="width: 100px" v-text="quantity" />
                </div>
              </div>
              <div class="custom-designs" v-if="constructors.has(id)">
                <div class="heading">Your design</div>
                <div class="images">
                  <template v-for="(item, i) in constructors.get(id)">
                    <a :href="item.contentUrl" target="_blank" class="image-wrapper" :key="i">
                      <img :src="item.contentUrl" alt="" />
                    </a>
                  </template>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="col-lg-5 col-xs-12">
        <div class="row">
          <div class="col-lg-12 ma-2">
            <h3>Your comment:</h3>
            <p>{{ order.comment }}</p>
          </div>
          <div class="col-lg-12 ma-2">
            <h3>Status:</h3>
            <p>{{ order.statusName }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format, updateMeta } from 'Lib/Utils';

export default {
  name: 'OrderDetails',
  data() {
    /**
     * @prop {Order} order
     * @prop {Collection} products
     */
    return {
      loading: false,
      order: null,
      products: null,
      constructors: new Map(),
    };
  },
  computed: {
    orderId() {
      const superId = '' + this.$route.params.id;
      return +superId.substr(1);
    },
  },
  methods: {
    setLoader(state) {
      this.loading = state;
      this.$store.dispatch('loader', state);
    },
    loadOrderDetails() {
      this.setLoader(true);
      this.$api.getOrder(this.orderId).then(data => {
        this.order = data;
        Promise.all([
          this.loadProducts(data.productsIds),
          this.loadDesigns(this.order.constructorsEntries).then(designs => {
            this.constructors = new Map(designs);
          }),
        ]).then(() => {
          this.setLoader(false);
        });
      });
    },
    loadProducts(ids) {
      this.setLoader(true);
      this.$api.getProductsCollection({ id: ids, itemsPerPage: ids.length }).then(collection => {
        this.products = { ...this.products, ...Object.fromEntries(collection.items.map(item => [item.id, item])) };
      });
    },
    async loadDesigns(items) {
      if (!items.length) {
        return null;
      }
      return Promise.all(
        items.map(([product_id, urls]) => {
          return Promise.all(
            urls.map(async url => {
              const res = await this.$client.get(url);
              return res.data;
            })
          ).then(data => [product_id, data]);
        })
      );
    },
  },
  created() {
    this.loadOrderDetails();
  },
  beforeCreate() {
    const {
      params,
      meta: { metaInfo },
    } = this.$route;
    const { title } = metaInfo?.templates || {};
    if (title) {
      updateMeta({ title: format(metaInfo.templates.title, params) });
    }
  },
};
</script>

<style type="text/css" lang="scss">
.cart-row {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .cart-item {
    flex-direction: row;
    display: flex;
    width: 100%;
    align-items: center;
  }
  .custom-designs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 10px;

    .heading {
      margin-bottom: 10px;
    }

    .images {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin: 0 -5px;

      .image-wrapper {
        width: 50%;
        padding: 5px;
        img {
          width: 100%;
        }
      }
    }
  }
}
@media only screen and (max-width: 640px) {
  .cart-row {
    .custom-designs .images {
      flex-direction: column !important;

      .image-wrapper {
        width: 100% !important;
      }
    }

    .heading {
      text-align: center;
    }
  }
}
</style>
